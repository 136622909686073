import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import env from '../../../environment';
import {
	Layout,
	Row,
	Breadcrumb,
	Card,
	Col,
	Tabs,
	Steps,
	Button,
	message,
	Typography,
	Modal,
	Form,
	Select
} from 'antd';
import authClient from '../../../Auth/Auth';
import MortgageDetailStateComponent from './Components/status/Status';
import { useSelector, useDispatch } from 'react-redux';
import MortgageDatosComponent from './Components/data/DataAllOperation';
import Documents from './Components/documentation/documentationGlobalContent';
import Appraisal from './Components/appraisal/appraisal';
import Formalization from './Components/formalization/Formalization';
import { changeStatusJob } from '../../../store/FormalizationTasacion/actions';
import Reward from './Components/common/Reward';
import PageBank from './Components/bank/PageBank';
import queryString from 'query-string';
import MyBreadcrumb from '../../../Components/ModalsAndAlerts/MyBreadcrumb';
import SnoozeModalNew from '../../../Components/ModalsAndAlerts/SnoozeModalNew';
import moment from 'moment';
import { updateStateMortgages, showModalRedux } from '../../../store/mortgages/actions';
import { HomeOutlined, SwapOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import MortgageDetailStateComponentMobile from './Components/status/StatusMobile';
import NewPageFinancial from './Components/financial/NewPageFinancial';
import OperationClose from '../../../Components/ModalsAndAlerts/OperationClose';

const { Content } = Layout;
const { Meta } = Card;
const { TabPane } = Tabs;
const { Step } = Steps;
const { Text } = Typography;
const { Option } = Select;

export default (props) => {
	const dispatch = useDispatch();
	const [mortgage, setMortgage] = useState(undefined);
	const [mortgageData, setMortgageData] = useState(null);
	const [request, setRequest] = useState(false);
	const [keyNumber, setKeyNumber] = useState('1');
	const mortgageUpda = useSelector((state) => state.mortgages.updateMortgages);
	const showModal = useSelector((state) => state.mortgages.showModal);
	const [isTypeFriend, setIsTypeFriend] = useState(undefined);
	const [showMortgageCloseModal, setShowMortgageCloseModal] = useState(false);
	const statusJob = useSelector((state) => state.formaTasaci.statusJob);
	const [issuccess, setIssuccess] = useState(false);
	const [snooze, setSnooze] = useState(null);
	const location = useLocation();
	const navigate = useNavigate();
	const profile = authClient.getProfile();
	const query = queryString.parse(location.search);
	const tabs = query.tab;
	const subTab = query.subTab;
	const [changeToMortgageAll, setChangeToMortgageAll] = useState(false);
	const [form] = Form.useForm();
	const [valueTypeMortgage, setValueTypeMortgage] = useState(undefined);
	const [showModalSabadell, setShowModalSabadell] = useState(false);

	const { mortgageId } = useParams();

	const getParticipants = async () => {
		await axios
			.get(`${env.api.url}/v1/mortgages/data/get/${mortgageId}`, {
				headers: {
					Authorization: `Bearer ${authClient.getToken()}`
				}
			})
			.then((response) => {
				setMortgageData(response.data.data);
				setRequest(true);
			});
	};

	useEffect(() => {
		loadData();
		handleSnoozeApi();
		getParticipants();
	}, [showModal, mortgageId, mortgageUpda]);

	useEffect(() => {
		getknowPlanfriend();
	}, []);

	const getknowPlanfriend = () => {
		axios
			.get(`${env.api.url}/v1/operations/get-type/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setIsTypeFriend(response.data.data);
			});
	};

	const loadData = async () => {
		const requests = [];

		requests.push(
			axios.get(`${env.api.url}/api/hadmin/mortgages/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
		);

		Promise.all(requests).then((values) => {
			if (values) {
				setMortgage(values[0].data);
				dispatch(changeStatusJob(values[0].data.status));
			}
		});
	};

	const changeToMortgage = () => {
		setChangeToMortgageAll(!changeToMortgageAll);
	};

	const handleChangeAssign = (values) => {
		if (values.typeMortgage === undefined) {
			values.typeMortgage = valueTypeMortgage;
		}

		// api de pablo
		axios
			.post(
				`${env.api.url}/v1/mortgages/change-mortgage-type`,
				{
					operationId: mortgageId,
					mortgageType: values.typeMortgage
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setChangeToMortgageAll(!changeToMortgageAll);
				window.location.reload();
			});
	};

	const breadcrumb =
		mortgage && mortgage.name ? (
			<Breadcrumb.Item>
				{' '}
				<span>{mortgage.name}</span>
			</Breadcrumb.Item>
		) : null;

	let meta;

	const handleChange = (key) => {
		setKeyNumber(key);
		navigate(`/operations/mortgages/${mortgageId}/?tab=${key}`);
	};

	const handleSnoozeApi = async () => {
		await axios
			.get(`${env.api.url}/api/hadmin/get-mortgage-reminder/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setSnooze(response.data[0]);
			});
	};

	const updateSnooze = () => {
		axios
			.get(`${env.api.url}/api/hadmin/reminder/wakeup/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((res) => {
				setIssuccess(true);
				message.success('Su snooze se elimino correctamente.');
				dispatch(updateStateMortgages(!mortgageUpda));
			});
	};

	if (showModal) {
		return <SnoozeModalNew mortgageId={mortgageId} />;
	}
	const stages1 = {
		basic: 0,
		full: 1,
		documents: 2,
		analysis: 3,
		banks: 4,
		oferts: 5,
		appraisal: 6,
		formalization: 7,
		finish: 8
	};

	const textOperationType = (type) => {
		if (
			type === 'mortgage' &&
			(mortgageData?.mortgage?.selfPromoter === undefined || !mortgageData?.mortgage?.selfPromoter)
		) {
			return 'Hipoteca';
		} else if (type === 'mortgage' && mortgageData?.mortgage?.selfPromoter) {
			return 'Autopromoción';
		} else if (type === 'green-mortgage') {
			return 'Hipoteca verde';
		} else if (type === 'subrogation') {
			return 'Subrogación';
		} else if (type === 'green-subrogation') {
			return 'Subrogación verde';
		}
	};

	const newContent = (
		<div>
			{isMobile && (
				<MortgageDetailStateComponentMobile
					mortgageId={mortgageId}
					mortgage={mortgage}
					snooze={snooze}
				/>
			)}
			{!isMobile && (
				<Card
					loading={mortgage && mortgage.name == null}
					style={{ padding: '8px' }}
					className="gcard">
					<div className="scrollStepTypeMortgages" style={{ marginBottom: '32px' }}>
						<Row>
							<Col lg={17} xs={24}>
								<Steps
									current={mortgage && stages1[mortgage.stage]}
									className="gb-steps-styles-common"
									style={{ width: 'calc(100% - 100px)' }}>
									<Step title="" description="Básico" className="stepOneBasic" />
									<Step title="" description="Perfil" className="stepTwoBasic" />
									<Step title="" description="Documentación" className="stepThreeBasic" />
									<Step title="" description="Analisis" className="stepFourBasic" />
									<Step title="" description="Bancos" className={'stepFiveBasic'} />
									<Step title="" description="Selección oferta" className={'stepSixBasic'} />
									{mortgage && stages1[mortgage.stage] === 7 ? (
										<Step title="" description="Tasación" className={'stepSevenBasic1'} />
									) : (
										<Step title="" description="Tasación" className={'stepSevenBasic'} />
									)}
									<Step title="" description="Formalización" className={'stepEightBasic'} />
								</Steps>
							</Col>
							<Col lg={7} xs={24} style={{ textAlign: 'right' }}>
								<Button
									type="dashed"
									style={{
										marginRight: '8px',
										height: 24,
										width:
											mortgage &&
											(mortgage.operationStatus === 'postpone' ||
												mortgage.operationStatus === 'reminder') &&
											snooze != null
												? 'auto'
												: 72
									}}
									disabled={statusJob === 'finish' ? true : false}
									onClick={() =>
										mortgage &&
										(mortgage.operationStatus === 'postpone' ||
											mortgage.operationStatus === 'reminder') &&
										snooze != null
											? updateSnooze()
											: dispatch(showModalRedux())
									}
									className="button-secundary-gibobs">
									{mortgage &&
									(mortgage.operationStatus === 'postpone' ||
										mortgage.operationStatus === 'reminder') &&
									snooze != null
										? 'Despertar'
										: 'Dormir'}
								</Button>
								<Button
									type="primary"
									danger
									className="button-primary-danger-gibobs"
									disabled={statusJob === 'finish' ? true : false}
									onClick={() => {
										setShowMortgageCloseModal(true);
									}}
									style={{ width: '72px', height: 24 }}>
									Finalizar
								</Button>
								{mortgage?.managementCode === 'sabadell-es' && (
									<Button
										onClick={() => setShowModalSabadell(true)}
										disabled={statusJob === 'finish' ? true : false}
										className="button-sabadell"
										style={{
											width: 115,
											height: 24,
											marginLeft: 8
										}}>
										Ya en Sabadell
									</Button>
								)}
							</Col>
						</Row>

						{mortgage &&
						(mortgage.operationStatus === 'postpone' || mortgage.operationStatus === 'reminder') &&
						snooze != null ? (
							<Card className="CardSnoozeComponent">
								<Row span={24}>
									<Col lg={16}>
										<div>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858',
													marginRight: '4px'
												}}>
												Motivo:
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#FF4D4F'
												}}>
												{snooze && snooze.reason}
											</Text>
										</div>
										{console.log('snooze: ', snooze)}
										<div>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858',
													marginRight: '4px'
												}}>
												Submotivo:
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#FF4D4F'
												}}>
												{snooze && snooze.subreason}
											</Text>
										</div>
										<div>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858',
													marginRight: '4px'
												}}>
												Observaciones:
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#FF4D4F'
												}}>
												{snooze && snooze.body}
											</Text>
										</div>
									</Col>

									<Col lg={8} style={{ textAlign: 'right' }}>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: 'normal',
												color: '#FF4D4F'
											}}>
											Configurado recordatorio el {moment(snooze.date).format('DD/MM/YYYY')} por{' '}
											{snooze.agent}
										</Text>
									</Col>
								</Row>
							</Card>
						) : null}

						{mortgage && mortgage.closeDate !== null && mortgage.status === 'finish' ? (
							<Card className="CardFinishComponent">
								<Row span={24}>
									<Col lg={16} xs={24}>
										<div
											style={{
												marginBottom: '4px',
												lineHeight: '15px'
											}}>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858',
													marginRight: '4px'
												}}>
												Causa de cierre:
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#1890FF'
												}}>
												{mortgage && mortgage.closeReason}
											</Text>
										</div>
										<div
											style={{
												marginBottom: '4px',
												lineHeight: '17px'
											}}>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858',
													marginRight: '4px'
												}}>
												Subcausa de cierre:
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#1890FF'
												}}>
												{mortgage && mortgage.closeSubreason && mortgage.closeSubreason !== ' '
													? mortgage.closeSubreason
													: '-'}
											</Text>
										</div>
										<div style={{ display: 'flex' }}>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858',
													minWidth: 132
												}}>
												Descripción de cierre:
											</Text>
											{mortgage && (
												<p
													className="descriptionClosedReason"
													style={{ marginTop: 0 }}
													dangerouslySetInnerHTML={{
														__html: mortgage.closeReasonDescription
													}}></p>
											)}
										</div>
									</Col>
									<Col lg={8} xs={24} style={{ textAlign: 'right' }}>
										<div
											style={{
												marginBotton: '4px',
												lineHeight: '18px'
											}}>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858',
													marginRight: '4px'
												}}>
												Fecha de cierre:
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#1890FF'
												}}>
												{mortgage && moment(mortgage.closeDate).format('DD/MM/YYYY')}
												{/* {snooze && snooze.reason} */}
											</Text>
										</div>
										<div
											style={{
												marginBotton: '4px',
												lineHeight: '18px'
											}}>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858',
													marginRight: '4px'
												}}>
												Persona que la ha cerrado:
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#1890FF'
												}}>
												{'-'}
											</Text>
										</div>

										<div style={{ marginTop: 8 }}>
											{profile.email === 'fernando.lopez@gibobs.com' ||
											profile.email === 'jaime.aguirre@gibobs.com' ||
											profile.email === 'irene.masa@gibobs.com' ||
											profile.email === 'juan.benavides@gibobs.com' ||
											profile.email === 'silvia.varela@gibobs.com' ||
											profile.email === 'jose.hernandez@gibobs.com' ||
											profile.email === 'miriam.torrens@gibobs.com' ||
											profile.email === 'pau.costa@gibobs.com' ||
											profile.email === 'javier.canadilla@gibobs.com' ||
											profile.email === 'pablo.cabanyes@gibobs.com' ||
											profile.email === 'yanina.aguirre@gibobs.com' ||
											profile.email === 'sergio.martin@gibobs.com' ||
											profile.email === 'israel.tena@gibobs.com' ||
											profile.email === 'pau.costa@gibobs.com' ||
											profile.email === 'mireia.martin@gibobs.com' ||
											profile.email === 'adrian.pantoja@gibobs.com' ||
											profile.email === 'adriana.gaitan@gibobs.com' ||
											profile.email === 'maria.ramirez@gibobs.com' ||
											profile.email === 'gonzalo.oliver@gibobs.com' ||
											profile.email === 'paloma.bellas@gibobs.com' ||
											profile.email === 'alejandro.lois@gibobs.com' ||
											profile.email === 'christian.andres@gibobs.com' ||
											profile.type === 'risk' ? (
												<Button
													type="primary"
													className="button-primary-danger-gibobs"
													onClick={() => {
														axios
															.get(`${env.api.url}/v1/mortgages/open-operation/${mortgageId}`, {
																headers: {
																	Authorization: `Bearer ${authClient.getToken()}`
																}
															})
															.then(() => window.location.reload(false));
													}}
													style={{ width: '70px', marginLeft: '16px' }}>
													Reabrir
												</Button>
											) : (
												<div></div>
											)}
										</div>
									</Col>
								</Row>
							</Card>
						) : null}
					</div>

					{meta}

					<div style={{ marginBottom: '-16px', display: 'flex' }}>
						<div
							style={{
								color: '#02C3CD',
								fontSize: '14px',
								fontWeight: 500,
								fontStyle: 'normal',
								position: 'absolute',
								right: '30px',
								display: 'flex'
							}}>
							{mortgage && mortgage.subtype && mortgage.subtype === 'subrogation' ? (
								<SwapOutlined
									style={{
										color: '#2F4858',
										fontSize: '14px',
										marginRight: '8px'
									}}
								/>
							) : mortgage &&
							  mortgage.subtype &&
							  mortgage.subtype !== 'subrogation' &&
							  mortgage.subtype !== 'green-subrogation' ? (
								<HomeOutlined
									style={{
										color:
											mortgage && mortgage.subtype === 'green-mortgage' ? '#52C41A' : '#2F4858',
										fontSize: '18px',
										marginRight: '8px'
									}}
								/>
							) : (
								<SwapOutlined
									style={{
										color:
											mortgage && mortgage.subtype === 'green-subrogation' ? '#52C41A' : '#2F4858',
										fontSize: '18px',
										marginRight: '8px'
									}}
								/>
							)}

							<Text
								style={{
									color:
										mortgage &&
										(mortgage.subtype === 'green-mortgage' ||
											mortgage.subtype === 'green-subrogation')
											? '#52C41A'
											: '#2F4858',
									fontSize: '14px',
									fontWeight: 500,
									fontStyle: 'normal'
								}}>
								{mortgage && mortgage.subtype && mortgage.subtype === 'subrogation'
									? textOperationType(mortgage.subtype)
									: mortgage && mortgage.subtype && textOperationType(mortgage.subtype)}
							</Text>

							{/* poner un if para controlar que sea una autopromocion
							
							<Card
								style={{
									borderRadius: 4,
									border: '1px solid #01929A',
									height: 24,
									marginLeft: 8,
									background: '#EFFCFC',
									width: 100
								}}>
								<Text style={{ fontSize: 12, fontWeight: 500, color: '#01929A', position: "absolute", top: 0, left: 6 }}>
									Autopromoción
								</Text>
							</Card>*/}
						</div>
					</div>
					<Tabs
						defaultActiveKey={tabs !== undefined ? tabs : '1'}
						onChange={(e) => handleChange(e)}>
						<TabPane
							key="1"
							tab={
								<NavLink
									state={{
										key: '1'
									}}
									style={{
										fontSize: '14px',
										color: tabs === '1' ? '#02C3CD' : '#2F4858',
										fontWeight: tabs === '1' ? 600 : 'normal'
									}}>
									{/* <span> */}
									{/* <Icon type="reconciliation" />  */}
									Resumen
									{/* </span> */}
								</NavLink>
							}>
							<MortgageDetailStateComponent
								mortgageId={mortgageId}
								mortgage={mortgage}
								mortgageData={mortgageData}
								isTypeFriend={isTypeFriend}
							/>
						</TabPane>

						<TabPane
							key="2"
							tab={
								<NavLink
									state={{
										key: '2'
									}}
									style={{
										fontSize: '14px',
										color: tabs === '2' ? '#02C3CD' : '#2F4858',
										fontWeight: tabs === '2' ? 600 : 'normal'
									}}>
									<span>
										{/* <Icon type="reconciliation" />  */}
										Datos
									</span>
								</NavLink>
							}>
							<MortgageDatosComponent
								mortgageId={mortgageId}
								mortgage={mortgage}
								otherDataAdditional={mortgageData?.otherDataAdditional}
							/>
						</TabPane>

						<TabPane
							key="3"
							disabled={
								mortgage && (mortgage.stage === 'basic' || mortgage.stage === 'full') ? true : false
							}
							tab={
								<NavLink
									state={{
										key: '3'
									}}
									style={{
										fontSize: '14px',
										color:
											tabs === '3'
												? '#02C3CD'
												: mortgage && (mortgage.stage === 'basic' || mortgage.stage === 'full')
												? '#748EA0'
												: '#2F4858',
										fontWeight: tabs === '3' ? 600 : 'normal'
									}}>
									<span>Documentación</span>
								</NavLink>
							}>
							<Documents
								mortgageId={mortgageId}
								nameMortgage={mortgage && mortgage.name}
								subTab={subTab}
								tabs={'3'}
								mortgage={mortgage}
							/>
						</TabPane>

						<TabPane
							tab={
								<NavLink
									state={{
										key: '4'
									}}
									style={{
										fontSize: '14px',
										color: tabs === '4' ? '#02C3CD' : '#2F4858',
										fontWeight: tabs === '4' ? 600 : 'normal'
									}}>
									<span>
										{/* <SnippetsOutlined />  */}
										Financiero
									</span>
								</NavLink>
							}
							key="4">
							{mortgageData && mortgageData?.mortgage?.maxBudget ? (
								<NewPageFinancial
									mortgageId={mortgageId}
									maxBudget={true}
									mortgage={mortgage}
									mortgageData={mortgageData && mortgageData}
								/>
							) : (
								<NewPageFinancial
									mortgageId={mortgageId}
									mortgage={mortgage}
									mortgageData={mortgageData && mortgageData}
								/>
							)}
						</TabPane>

						<TabPane
							tab={
								<NavLink
									state={{
										key: '5'
									}}
									style={{
										fontSize: '14px',
										color: tabs === '5' ? '#02C3CD' : '#2F4858',
										fontWeight: tabs === '5' ? 600 : 'normal'
									}}>
									<span>
										{/* <BankOutlined /> */}
										Bancos
									</span>
								</NavLink>
							}
							key="5">
							<PageBank
								mortgageId={mortgageId}
								mortgageName={mortgage && mortgage.name}
								mortgage={mortgageData}
								type={mortgage}
								setType={setMortgage}
								loadData={() => loadData()}></PageBank>
						</TabPane>
						<TabPane
							tab={
								<NavLink
									state={{
										key: '6'
									}}
									style={{
										fontSize: '14px',
										color:
											tabs === '6'
												? '#02C3CD'
												: mortgage && (mortgage.stage === 'basic' || mortgage.stage === 'full')
												? '#748EA0'
												: '#2F4858',
										fontWeight: tabs === '6' ? 600 : 'normal'
									}}>
									<span>
										{/* <PercentageOutlined />  */}
										Gestiones bancarias{' '}
									</span>
								</NavLink>
							}
							disabled={
								mortgage && (mortgage.stage === 'basic' || mortgage.stage === 'full') ? true : false
							}
							key="6">
							<Appraisal mortgageId={mortgageId} />
						</TabPane>
						<TabPane
							tab={
								<NavLink
									state={{
										key: '7'
									}}
									style={{
										fontSize: '14px',
										color:
											tabs === '7'
												? '#02C3CD'
												: mortgageData && mortgageData?.mortgage?.bankAppraisalApproval !== true
												? '#748EA0'
												: '#2F4858',
										fontWeight: tabs === '7' ? 600 : 'normal'
									}}>
									<span>
										{/* <FileDoneOutlined />  */}
										Formalización{' '}
									</span>
								</NavLink>
							}
							disabled={
								mortgageData && mortgageData?.mortgage?.bankAppraisalApproval === true
									? false
									: true
							}
							key="7">
							<Formalization mortgageId={mortgageId} />
						</TabPane>
						{mortgageData &&
						mortgageData?.mortgage?.ringTheBell === true &&
						isTypeFriend &&
						isTypeFriend.operationType === 'friend' ? (
							<TabPane
								tab={
									<NavLink
										state={{
											key: '8'
										}}
										style={{
											fontSize: '12px',
											color: tabs === '8' ? '#02C3CD' : '#2F4858',
											fontWeight: tabs === '8' ? 600 : 'normal'
										}}>
										<span>
											{/* <EuroCircleOutlined />  */}
											Recompensa{' '}
										</span>
									</NavLink>
								}
								key="8">
								<Reward
									mortgageId={mortgageId}
									mortgage={mortgage}
									mortgageDataGet={mortgageData}
								/>
							</TabPane>
						) : null}
					</Tabs>
				</Card>
			)}
		</div>
	);

	if (mortgage && mortgage.name) {
		meta = (
			<Meta
				title={`Hipoteca ${mortgage.name}`}
				description="Detalles de la solicitud hipotecaria"
			/>
		);
	}

	const data = [
		{
			name: 'Inicio',
			link: '/'
		},
		{
			name: 'Seguimiento',
			link: '/operations/mortgages/list/active'
		},
		{
			name: breadcrumb,
			link: undefined
		}
	];

	const closeMortgage = async () => {
		axios
			.post(
				`${env.api.url}/api/hadmin/mortgage-close`,
				{
					mortgageId: mortgageId,
					applicant: 'client',
					closeReason: 'Continúa con otro banco',
					subCloseReason: 'Sabadell',
					closeAllTask: true,
					body: '<p></p>'
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					message.success('Su operación se ha cerrado correctamente.');
					setShowModalSabadell(false);
					dispatch(changeStatusJob('finish'));
					loadData();
				} else {
					message.error('No se ha podido cerrar la operación');
				}
			})
			.catch(() => message.error('No se ha podido cerrar la operación'));
	};

	return (
		<Layout className="page-mortgages">
			<Content
				style={{
					padding: isMobile ? '24px 16px' : '24px 40px',
					minHeight: 280
				}}>
				<MyBreadcrumb data={data}></MyBreadcrumb>

				<div style={{ height: 24, float: 'right', position: 'absolute', top: 90, right: 40 }}>
					<Button
						type="primary"
						className="button-primari-gibobs"
						style={{ height: 24, fontSize: 12 }}
						onClick={() => changeToMortgage()}>
						Convertir operación
					</Button>
				</div>

				<Content style={{ padding: '20px 0', minHeight: 280 }}>
					<Row gutter={16}>
						<Col span={24}>
							{/* <StepperMiga/> */}
							{newContent}
						</Col>
					</Row>
				</Content>
				{showMortgageCloseModal && (
					<OperationClose
						mortgageId={mortgageId}
						visible={showMortgageCloseModal}
						onSuccess={() => setShowMortgageCloseModal(false)}
						onCancel={() => setShowMortgageCloseModal(false)}
						loadData={() => loadData()}
						type={mortgage && mortgage.subtype}
					/>
				)}
			</Content>
			<Modal
				className="gb-modal-style-common"
				title="Convertir operación"
				open={changeToMortgageAll}
				onCancel={() => {
					setChangeToMortgageAll(!changeToMortgageAll);
				}}
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 40, width: '46%', marginLeft: 20 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { height: 40, width: '46%' } }}
				destroyOnClose={true}
				width={480}
				onOk={() => {
					form
						.validateFields()
						.then((values) => {
							handleChangeAssign(values);
							form.resetFields();
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}
				okText={'Confirmar'}
				cancelText={'Cancelar'}>
				<div style={{ marginTop: 0 }}>
					<Text
						style={{
							fontSize: 12,
							fontWeight: 400,
							color: '2F4858'
						}}>
						Elige el tipo de operación al que deseas cambiar esta operación
					</Text>
				</div>
				<div style={{ display: 'flex', marginTop: 16, width: '100%' }}>
					<Form form={form} layout="vertical" name="basic" style={{ width: '98%' }}>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item
									label={
										<Text
											style={{
												fontSize: 10,
												fontWeight: 400,
												color: '2F4858'
											}}>
											Tipo de hipoteca
										</Text>
									}
									name="typeMortgage"
									style={{ width: '100%', fontSize: 10, fontWeight: 400, color: '2F4858' }}>
									{' '}
									<Select
										showSearch
										style={{ width: '100%' }}
										placeholder="Seleccione"
										onChange={(values) => setValueTypeMortgage(values)}>
										<Option key={1} value="mortgage">
											{'Hipoteca'}
										</Option>
										<Option key={2} value="subrogation">
											{'Subrogación'}
										</Option>
										<Option key={4} value="selfpromoter">
											{'Autopromoción'}
										</Option>
										<Option key={3} value="green-mortgage">
											{'Hipoteca verde'}
										</Option>
										<Option key={4} value="green-subrogation">
											{'Subrogación verde'}
										</Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
			<Modal
				className="gb-modal-40-padding"
				open={showModalSabadell}
				centered
				width={480}
				title="¿Estás seguro de que quieres cerrar esta operación porque el cliente continúa por otro canal en Banco Sabadell?"
				onCancel={() => setShowModalSabadell(false)}
				footer={
					<div style={{ display: 'flex', width: '100%' }}>
						<Button
							type="dashed"
							onClick={() => setShowModalSabadell(false)}
							style={{ width: '50%', marginLeft: 40 }}
							className="button-secundary-inside-gibobs">
							Atrás
						</Button>
						<Button
							type="primary"
							onClick={closeMortgage}
							style={{ width: '50%', marginRight: 40 }}>
							Aceptar
						</Button>
					</div>
				}></Modal>
		</Layout>
	);
};
