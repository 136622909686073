import React, { useEffect, useState } from 'react';
import { Content } from 'antd/es/layout/layout';

import {
	Button,
	Upload,
	Col,
	message,
	Radio,
	Row,
	Input,
	Checkbox,
	Modal,
	Typography,
	notification,
	DatePicker,
	Select,
	Divider
} from 'antd';
import authClient from '../../../../../Auth/Auth';
import axios from 'axios';
import env from '../../../../../environment';
import Loading from '../../../../../Components/ModalsAndAlerts/Loading';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';
import { InputNumber } from 'afrodita';
import _ from 'lodash';
import RegisterAssigment from '../bank/registerAssignment/registerAssigment';
import dayjs from 'dayjs';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

export default (props) => {
	const { mortgageId } = props;
	const [mortgageData, setMortgageData] = useState();
	const [fileName, setFileName] = useState('');
	const statusJob = useSelector((state) => state.formaTasaci.statusJob);
	const [billingFormData, setBillingFormData] = useState(undefined);
	const [isSabadell, setIsSabadell] = useState(undefined);
	const [dataBankRequest, setDataBankRequest] = useState(undefined);
	const [visibleConfirmComponent, setVisibleConfirmComponent] = useState(false);
	const [bankOffers, setBankOffers] = useState(undefined);
	const [favOffer, setFavOffer] = useState(undefined);
	const [modalConfirmBack, setModalConfirmBack] = useState(false);
	const [rejectionRows, setRejectionRows] = useState([]);
	const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
	const [deleteIndex, setDeleteIndex] = useState(undefined);
	const [mortgageOperation, setMortgageOperation] = useState(undefined);

	const rejectionReasonsOptions = [
		{ value: 'missingDocumentation', label: 'Falta documentación' },
		{ value: 'missingInformation', label: 'Falta información' },
		{ value: 'dataError', label: 'Error en los datos (guía de precios, tipos de interés...)' },
		{ value: 'cirbe', label: 'CIRBE' },
		{ value: 'dhDiscrepancy', label: 'Discrepancia de criterio con DH (denegada por el DH)' },
		{ value: 'reprocessingDueToError', label: 'Retramitación por error en gestoría' },
		{ value: 'noReasonProvidedByCH', label: 'El CH no indica el motivo' },
		{ value: 'other', label: 'Otro' }
	];

	const mortgageDirectorOptions = [
		{ value: 'Cristina Alonso', label: 'Cristina Alonso' },
		{ value: 'Concepción Primo', label: 'Concepción Primo' },
		{ value: 'Eduardo Olmos', label: 'Eduardo Olmos' },
		{ value: 'Isabel Torr.', label: 'Isabel Torr.' },
		{ value: 'Jorge Benito', label: 'Jorge Benito' },
		{ value: 'Julia Estopina', label: 'Julia Estopina' },
		{ value: 'Marta Fernandez', label: 'Marta Fernandez' },
		{ value: 'Pilar Andreu', label: 'Pilar Andreu' },
		{ value: 'Pilar Ejarque', label: 'Pilar Ejarque' },
		{ value: 'Pilar Frutos', label: 'Pilar Frutos' },
		{ value: 'Sonia Soriano', label: 'Sonia Soriano' },
		{ value: 'Victor Moratal', label: 'Victor Moratal' },
		{ value: 'Otro', label: 'Otro' }
	];

	useEffect(() => {
		getMortgageData();
	}, [mortgageId]);

	useEffect(() => {
		getManagementId();
		getBankOffers();
		getBillingData();
	}, []);

	useEffect(() => {
		setFavOffer(bankOffers?.find((offer) => offer.isFavorite));
	}, [bankOffers]);

	const getMortgageData = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/data/get/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setMortgageOperation(response.data.data.operation);
				setMortgageData(response.data.data.mortgage);
				setRejectionRows(response.data.data.recommendedResult.rejections || []);
			})
			.catch((error) => {
				console.error('Error al obtener los datos de la hipoteca:', error);
			});
	};

	const getBillingData = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/data/get/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				const billingData = response.data.data.billing || {};
				const owner1Data = response.data.data.owner1 || {};

				const newBilling = {
					name: billingData.name || renderName(owner1Data),
					direction: billingData.direction || renderAddress(owner1Data),
					nif: billingData.nif || owner1Data.nif || ''
				};
				setBillingFormData(newBilling);
				saveBilling(newBilling.name, newBilling.direction, newBilling.nif);
			})
			.catch((error) => {
				console.error('Error al obtener los datos de la hipoteca:', error);
			});
	};

	const saveBilling = (name, address, nif) => {
		axios.post(
			`${env.api.url}/v1/mortgages/data/multiple/update/${mortgageId}`,
			{
				values: [
					{
						key: 'billing',
						subkey: 'name',
						value: name
					},
					{
						key: 'billing',
						subkey: 'direction',
						value: address
					},
					{
						key: 'billing',
						subkey: 'nif',
						value: nif
					}
				]
			},
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);
	};

	const renderName = (owner1) => {
		return (
			(owner1?.name || '') +
			(owner1?.firstSurname ? ' ' + owner1.firstSurname : '') +
			(owner1?.lastSurname ? ' ' + owner1.lastSurname : '')
		);
	};

	const renderAddress = (owner1) => {
		return (
			(owner1?.address ? owner1.address : '') +
			(owner1?.population ? ', ' + owner1.population : '') +
			(owner1?.postalCode ? ', ' + owner1.postalCode : '') +
			(owner1?.province ? ', ' + owner1.province : '')
		);
	};

	const getBankOffers = () => {
		axios
			.get(`${env.api.url}/banks/bank-offer/find-by-operation/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setBankOffers(response.data.data);
			});
	};

	const getRequests = () => {
		axios
			.get(`${env.api.url}/v1/banks/get-operation-bank-requests/${mortgageId}?hadmin=${true}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				response.data.data = _.orderBy(response.data.data, [(d) => d.id], ['desc']);
				setDataBankRequest(response.data.data);
			});
	};

	const getManagementId = () => {
		axios
			.post(
				`${env.api.url}/management/management/by-operation`,
				{ operationId: mortgageId },
				{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
			)
			.then((response) => {
				if (response.data.data.code === 'sabadell-es') {
					getRequests();
					setIsSabadell(true);
				} else {
					setIsSabadell(false);
				}
			});
	};

	const saveData = (participant, key, value) => {
		setTimeout(() => {
			message.success('Se actualizo con exito');
		}, 2000);
		if (key === 'appraisalPaid') {
			saveData('mortgage', 'paidAppraisalDate', moment().format('YYYY-MM-DD'));
		}

		if (value === 'Invalid Date') {
			value = null;
		}

		axios
			.post(
				`${env.api.url}/v1/mortgages/data/update/${mortgageId}`,
				{ key, participant, value, origin: 'https://app.gibobs.com' },
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => {
				getMortgageData();
				if (key === 'bankAppraisalApproval') {
					window.location.reload();
				}
			});
	};

	if (!mortgageData || !billingFormData || isSabadell === undefined) {
		return <Loading />;
	}

	const saveDataOnClick = (participant, key, value) => {
		let valueRadio = {
			key: 'operation',
			subkey: 'firstClickToAppraisal',
			value: moment().format('YYYY-MM-DD')
		};

		if (
			mortgageData.reportAppraisal !== undefined &&
			mortgageOperation.firstClickToAppraisal !== undefined
		) {
			valueRadio = {
				key: 'operation',
				subkey: 'ultimateClickToAppraisal',
				value: moment().format('YYYY-MM-DD')
			};
		}

		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${mortgageId}`,
				{
					values: [
						{
							key: participant,
							subkey: key,
							value: value
						},
						{
							key: valueRadio.key,
							subkey: valueRadio.subkey,
							value: valueRadio.value
						}
					],
					origin: 'https://app.gibobs.com'
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				getMortgageData();
			});
	};

	const dataMultiple = (key, owner, value, key1, owner1, value1) => {
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${mortgageId}`,
				{
					values: [
						{
							key: key,
							subkey: owner,
							value: value
						},
						{
							key: key1,
							subkey: owner1,
							value: value1
						}
					]
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => {
				getMortgageData();
			});
	};

	const saveFileData = (file, name) => {
		axios
			.put(
				`${env.api.url}/api/mortgages/attachment`,
				{
					mortgageId,
					file,
					fileName: name,
					isOnAgent: true,
					type: 'appraisal',
					page: '1'
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => {
				message.success('Se actualizo con exito');
			});
	};

	const uploadProps = {
		beforeUpload: (file) => {
			setFileName(file.name);
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64File = reader.result.split(',')[1];
					saveFileData(base64File, file.name);
				};
			});
		}
	};

	const showModalconfirm = () => {
		const modal = Modal.confirm({
			title: 'Confirmar aprobación',
			content: (
				<Text>
					¿Estás seguro de que quieres confirmar la aprobación de la tasación por parte del banco?
				</Text>
			),
			closable: true,
			okCancel: true,
			width: 480,
			className: 'gb-modal-24-padding',
			okButtonProps: { className: 'button-primari-gibobs' },
			cancelButtonProps: { className: 'button-secundary-gibobs' },
			onOk: () => {
				saveData('mortgage', 'bankAppraisalApproval', true);
			},
			okText: 'Confirmar',
			cancelText: 'Cancelar',
			onCancel: () => {
				modal.destroy();
			}
		});
	};

	const sendEmail = (info) => {
		axios
			.post(
				`${env.api.url}/v1/banks/send-mail-call-insurance`,
				{
					bankOfferId: favOffer.id
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => {
				getMortgageData();
				if (info) {
					notification.success({
						message: <Text style={{ color: '#3E9314', fontSize: 16, fontWeight: 600 }}></Text>,
						description: (
							<Text
								style={{
									color: '#2F4858',
									fontSize: 14,
									fontWeight: 400
								}}>
								{'Se ha solicitado la llamada de seguros para tu cliente.'}
							</Text>
						),
						duration: 0,
						style: {
							background: '#DFF4D4',
							borderColor: '#52C41A',
							borderRadius: 4
						}
					});
				}
			});
	};

	const handleSubmitForm = (values, id) => {
		const objAsigment = {
			bankRequestId: dataBankRequest[0].id,
			bankAccountData: {
				name: values.name === undefined ? '' : values.name,
				email: values.email,
				phone: values.phone === undefined ? '' : values.phone,
				office: values.office === undefined ? '' : values.office,
				reference: values.reference === undefined ? '' : values.reference
			}
		};
		axios
			.post(`${env.api.url}/banks/bank-request/assign-to-account-executive`, objAsigment, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setVisibleConfirmComponent(false);
				notification.success({
					message: <Text style={{ color: '#3E9314', fontSize: 16, fontWeight: 600 }}></Text>,
					description: (
						<Text
							style={{
								color: '#2F4858',
								fontSize: 14,
								fontWeight: 400
							}}>
							{
								'El ejecutivo de cuentas se ha registrado correctamente y ya hemos solicitado la llamada de seguros para tu cliente. '
							}
						</Text>
					),
					duration: 0,
					style: {
						background: '#DFF4D4',
						borderColor: '#52C41A',
						borderRadius: 4
					}
				});
				sendEmail(false);
			});
	};

	const checkLinkages = (linkages) => {
		if (linkages) {
			const homeChecked = linkages.hasOwnProperty('home') && linkages.home.isChecked === true;
			const insecuranceChecked =
				linkages.hasOwnProperty('insecurance') && linkages.insecurance.isChecked === true;
			const protectedPaymentsChecked =
				linkages.hasOwnProperty('protectedPayments') &&
				linkages.protectedPayments.isChecked === true;

			return homeChecked || insecuranceChecked || protectedPaymentsChecked;
		} else {
			return false;
		}
	};

	const disabledButtonCall = () => {
		if (favOffer) {
			if (checkLinkages(favOffer.data?.entailments[0]?.linkages)) {
				if (mortgageData.sendEmailInsecurance !== undefined) {
					return true;
				} else {
					return false;
				}
			} else {
				return true;
			}
		} else {
			return true;
		}
	};

	const renderTextButtonCall = () => {
		if (favOffer) {
			if (checkLinkages(favOffer.data?.entailments[0]?.linkages)) {
				if (mortgageData.sendEmailInsecurance !== undefined) {
					return `Llamada solicitada (${moment(mortgageData.sendEmailInsecuranceDate).format(
						'DD/MM/YYYY'
					)})`;
				} else {
					return 'Solicitar llamada';
				}
			} else {
				return 'Oferta sin vinculaciones (llamada no necesaria)';
			}
		} else {
			return 'No existe ninguna oferta favorita';
		}
	};

	const handleChange = (index, key, value) => {
		const updatedRows = [...rejectionRows];
		updatedRows[index][key] = value;
		setRejectionRows(updatedRows);
		saveData('recommendedResult', 'rejections', updatedRows);
	};

	const handleOnAdd = () => {
		const updatedRows = [...rejectionRows];
		updatedRows.push({
			date: undefined,
			mortgageDirector: undefined,
			reason: undefined,
			explanation: undefined
		});
		setRejectionRows(updatedRows);
	};

	const handleOnDelete = () => {
		const updatedRows = [...rejectionRows];
		updatedRows.splice(deleteIndex, 1);
		setRejectionRows(updatedRows);
		setModalConfirmDelete(false);
		saveData('recommendedResult', 'rejections', updatedRows);
	};

	const showModalConfirmDelete = (index) => {
		setModalConfirmDelete(true);
		setDeleteIndex(index);
	};

	return (
		<Content style={{ paddingLeft: 10 }}>
			{isSabadell && (
				<>
					<div style={{ marginBottom: 16 }}>
						<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
							REGISTRO DE TIEMPOS
						</Text>
					</div>
					<div
						style={{
							background: 'var(--Secondary-L3, #ECF7FC)',
							padding: '8px 16px',
							borderRadius: 8
						}}>
						<Row align={'middle'}>
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
								Llamada de seguros
							</Text>
							<Button
								disabled={disabledButtonCall()}
								onClick={() =>
									favOffer?.bankRequest?.bankAccountData?.email === undefined
										? setVisibleConfirmComponent(true)
										: sendEmail(true)
								}
								className="button-secundary-gibobs"
								style={{ marginLeft: 92 }}>
								{renderTextButtonCall()}
							</Button>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Checkbox
									name="llamada"
									checked={mortgageData.insuranceCall ? true : false}
									onChange={(e) =>
										dataMultiple(
											'mortgage',
											'insuranceCall',
											e.target.checked,
											'mortgage',
											'insuranceCallDate',
											moment().format('YYYY-MM-DD')
										)
									}
									style={{ marginLeft: 30 }}
									disabled={statusJob === 'finish' ? true : false}>
									<span style={{ color: '#748EA0' }}>{'Llamada a cliente realizada'}</span>

									{mortgageData.insuranceCall && (
										<span style={{ color: '#748EA0' }}>
											{'(' + moment(mortgageData.insuranceCallDate).format('DD/MM/YYYY') + ')'}
										</span>
									)}
								</Checkbox>
							</div>
						</Row>
					</div>
				</>
			)}

			{isSabadell && (
				<div>
					<Row style={{ marginTop: 16 }} align={'middle'}>
						<Col span={6}>
							<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
								Expediente a análisis
								<br />
								<span style={{ fontWeight: 400 }}>(Recomendador enviado a análisis)</span>
							</Text>
						</Col>
						<Col span={12}>
							<div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>Solicitud</Text>
									<DatePicker
										disabled={statusJob === 'finish' ? true : false}
										style={{ width: 128, marginTop: 8 }}
										onChange={(date, dateString) =>
											saveData('mortgage', 'requestVDs', dayjs(date).format('YYYY-MM-DD'))
										}
										format={'DD-MM-YYYY'}
										placeholder={'Fecha'}
										defaultValue={
											mortgageData.requestVDs ? dayjs(mortgageData.requestVDs) : undefined
										}
									/>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>Respuesta</Text>
									<DatePicker
										disabled={statusJob === 'finish' ? true : false}
										style={{ width: 128, marginTop: 8 }}
										onChange={(date, dateString) =>
											saveData('mortgage', 'responseVDs', dayjs(date).format('YYYY-MM-DD'))
										}
										format={'DD-MM-YYYY'}
										placeholder={'Fecha'}
										defaultValue={
											mortgageData.responseVDs ? dayjs(mortgageData.responseVDs) : undefined
										}
									/>
								</div>
								<Text
									onClick={handleOnAdd}
									style={{
										fontSize: 14,
										fontWeight: 500,
										color: '#02C3CD',
										marginLeft: 40,
										cursor: 'pointer'
									}}>
									Añadir rechazo +
								</Text>
							</div>
						</Col>
					</Row>
					{rejectionRows.length > 0 && (
						<Row style={{ marginTop: 16 }}>
							<Col span={6} style={{ marginTop: 16 }}>
								<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
									Rechazo recomendador
								</Text>
							</Col>
							<Col span={18}>
								{rejectionRows.map((row, index) => {
									return (
										<React.Fragment key={index}>
											{index > 0 && (
												<Divider style={{ margin: '16px 0px', minWidth: 0, width: '95%' }} />
											)}
											<div
												style={{
													marginTop: index > 0 && 16,
													display: 'flex',
													alignItems: 'center',
													gap: 24
												}}>
												<Col span={22}>
													<Row gutter={16} align="middle">
														<Col flex="128px">
															<div style={{ display: 'flex', flexDirection: 'column' }}>
																<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>
																	Fecha
																</Text>
																<DatePicker
																	style={{ width: '128px', marginTop: 8 }}
																	onChange={(date) =>
																		handleChange(index, 'date', dayjs(date).format('YYYY-MM-DD'))
																	}
																	format={'DD-MM-YYYY'}
																	placeholder={'Fecha'}
																	defaultValue={
																		row.date ? dayjs(row.date, 'YYYY-MM-DD') : undefined
																	}
																/>
															</div>
														</Col>
														<Col flex="auto">
															<div style={{ display: 'flex', flexDirection: 'column' }}>
																<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>
																	Director hipotecario
																</Text>
																<Select
																	className="select-appraisal"
																	style={{ width: '100%', marginTop: 8 }}
																	placeholder="Seleccionar"
																	options={mortgageDirectorOptions}
																	onChange={(value) =>
																		handleChange(index, 'mortgageDirector', value)
																	}
																	defaultValue={row.mortgageDirector}
																/>
															</div>
														</Col>
														<Col flex="auto">
															<div style={{ display: 'flex', flexDirection: 'column' }}>
																<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>
																	Motivo
																</Text>
																<Select
																	className="select-appraisal"
																	style={{ width: '100%', marginTop: 8 }}
																	placeholder="Seleccionar"
																	options={rejectionReasonsOptions}
																	onChange={(value) => handleChange(index, 'reason', value)}
																	defaultValue={row.reason}
																/>
															</div>
														</Col>
													</Row>
													<Row gutter={16} align="middle" style={{ marginTop: 16 }}>
														<Col span={24}>
															<div style={{ display: 'flex', flexDirection: 'column' }}>
																<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>
																	Explicación
																</Text>
																<TextArea
																	style={{ width: '100%', marginTop: 8 }}
																	autoSize={{ minRows: 1, maxRows: 3 }}
																	placeholder="Indica cualquier otra cuestión"
																	onBlur={(e) => handleChange(index, 'explanation', e.target.value)}
																	defaultValue={row.explanation}
																/>
															</div>
														</Col>
													</Row>
												</Col>
												<Col span={2}>
													<DeleteOutlined
														onClick={() => showModalConfirmDelete(index)}
														style={{ fontSize: 18, color: '#FF7A7B', cursor: 'pointer' }}
													/>
												</Col>
											</div>
										</React.Fragment>
									);
								})}
							</Col>
						</Row>
					)}

					<Row style={{ marginTop: 16 }} align={'middle'}>
						<Col span={6} style={{ marginTop: 16 }}>
							<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
								Expediente a gestoría de scoring
							</Text>
						</Col>
						<Col span={18}>
							<div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>Solicitud</Text>
									<DatePicker
										disabled={statusJob === 'finish' ? true : false}
										style={{ width: 128, marginTop: 8 }}
										onChange={(date, dateString) =>
											saveData('mortgage', 'requestManagement', dayjs(date).format('YYYY-MM-DD'))
										}
										format={'DD-MM-YYYY'}
										placeholder={'Fecha'}
										defaultValue={
											mortgageData.requestManagement
												? dayjs(mortgageData.requestManagement)
												: undefined
										}
									/>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>Respuesta</Text>
									<DatePicker
										disabled={statusJob === 'finish' ? true : false}
										style={{ width: 128, marginTop: 8 }}
										onChange={(date, dateString) =>
											saveData('mortgage', 'responseManagement', dayjs(date).format('YYYY-MM-DD'))
										}
										format={'DD-MM-YYYY'}
										placeholder={'Fecha'}
										defaultValue={
											mortgageData.responseManagement
												? dayjs(mortgageData.responseManagement)
												: undefined
										}
									/>
								</div>
							</div>
						</Col>
					</Row>
					<Row style={{ marginTop: 16 }} align={'middle'}>
						<Col span={6} style={{ marginTop: 16 }}>
							<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
								Precio especial
							</Text>
						</Col>
						<Col span={18}>
							<div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>Solicitud</Text>
									<DatePicker
										disabled={statusJob === 'finish' ? true : false}
										style={{ width: 128, marginTop: 8 }}
										onChange={(date, dateString) =>
											saveData('mortgage', 'specialPriceRequest', dayjs(date).format('YYYY-MM-DD'))
										}
										format={'DD-MM-YYYY'}
										placeholder={'Fecha'}
										defaultValue={
											mortgageData.specialPriceRequest
												? dayjs(mortgageData.specialPriceRequest)
												: undefined
										}
									/>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>Respuesta</Text>
									<DatePicker
										disabled={statusJob === 'finish' ? true : false}
										style={{ width: 128, marginTop: 8 }}
										onChange={(date, dateString) =>
											saveData('mortgage', 'specialPriceResponse', dayjs(date).format('YYYY-MM-DD'))
										}
										format={'DD-MM-YYYY'}
										placeholder={'Fecha'}
										defaultValue={
											mortgageData.specialPriceResponse
												? dayjs(mortgageData.specialPriceResponse)
												: undefined
										}
									/>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>Resultado</Text>
									<Select
										className="select-appraisal"
										placeholder="Seleccionar"
										onChange={(value, dateString) =>
											saveData('mortgage', 'specialPriceStatus', value)
										}
										defaultValue={
											mortgageData.specialPriceStatus ? mortgageData.specialPriceStatus : undefined
										}
										style={{ width: 128, marginTop: 8 }}>
										<Option value="approved">Aprobada</Option>
										<Option value="deny">Denegada</Option>
									</Select>
								</div>
							</div>
						</Col>
					</Row>
					<Divider style={{ borderColor: '#F1F7F8' }} />
				</div>
			)}

			<Row style={{ marginTop: 16 }}>
				<Col span={12}>
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
						INFORME DE TASACIÓN
					</Text>
				</Col>
				<Col span={12}>
					<Upload {...uploadProps}>
						<Button
							disabled={statusJob === 'finish' ? true : false}
							type="dashed"
							className="button-secundary-gibobs"
							icon={<UploadOutlined />}>
							SUBIR INFORME DE TASACIÓN
						</Button>
						<small>{fileName}</small>
					</Upload>
				</Col>
			</Row>
			<Row style={{ marginTop: 16 }}>
				<Col span={12}>
					<Radio.Group
						style={{ display: 'flex', flexDirection: 'column', gap: 12 }}
						disabled={statusJob === 'finish' ? true : false}
						name="reportAppraisal"
						defaultValue={mortgageData.reportAppraisal}
						onChange={(e) => saveDataOnClick('mortgage', 'reportAppraisal', e.target.value)}>
						<Radio value={'h1'}>
							<Text style={{ fontSize: 14, color: '#748EA0', fontWeight: 400 }}>
								La tasación se hace con Gibobs
							</Text>
						</Radio>
						<Radio value={'own'}>
							<Text style={{ fontSize: 14, color: '#748EA0', fontWeight: 400 }}>
								La tasación la gestiona el cliente por su cuenta
							</Text>
						</Radio>
						<Radio value={'bank'}>
							<Text style={{ fontSize: 14, color: '#748EA0', fontWeight: 400 }}>
								La tasación la hace directamente el banco
							</Text>
						</Radio>
					</Radio.Group>
					<Row></Row>
					<div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
						<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>Tasadora</Text>
						<Input
							defaultValue={mortgageData.appraiser}
							disabled={statusJob === 'finish' ? true : false}
							onBlur={(e) => saveData('mortgage', 'appraiser', e.target.value)}
							type="text"
							placeholder="Tasadora"
							style={{ width: 128, marginTop: 8 }}
						/>
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
						<Col span={12}>
							<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>
								Coste tasación
							</Text>
							<div style={{ display: 'flex', alignItems: 'center', gap: 24, marginTop: 8 }}>
								<InputNumber
									disabled={statusJob === 'finish' ? true : false}
									style={{ width: 128, borderRadius: 4 }}
									defaultValue={mortgageData.appraisalAmount}
									placeholder="0"
									onBlur={(e) => {
										const inputValue = e.target.value.replace(/\./g, '').replace(',', '.');
										parseFloat(inputValue);
										if (
											numeral(inputValue).value() !== '' &&
											numeral(inputValue).value() !== null
										) {
											if (numeral(parseFloat(inputValue)).value() < 0) {
												message.error('El valor mínimo de la tasación debe ser 0');
											} else if (numeral(parseFloat(inputValue)).value() > 4000) {
												message.error('El valor máximo de la tasación debe ser 4.000 €');
											} else {
												saveData('mortgage', 'appraisalAmount', inputValue);
											}
										}
									}}
									gbType="currency"
								/>
								<Checkbox
									name="pagado"
									checked={mortgageData.appraisalPaid ? true : false}
									onChange={(e) =>
										dataMultiple(
											'mortgage',
											'appraisalPaid',
											e.target.checked,
											'mortgage',
											'paidAppraisalDate',
											moment().format('YYYY-MM-DD')
										)
									}
									disabled={statusJob === 'finish' ? true : false}>
									<Text style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}>Pagado</Text>
								</Checkbox>
							</div>
						</Col>
						<Col span={12}></Col>
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
						<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>
							Resultado tasación
						</Text>
						<InputNumber
							disabled={statusJob === 'finish' ? true : false}
							style={{ width: 128, marginTop: 8, borderRadius: 4 }}
							defaultValue={mortgageData.valueAppraisal}
							placeholder="0"
							onBlur={(e) => {
								const inputValue = e.target.value.replace(/\./g, '').replace(',', '.');
								parseFloat(inputValue);

								if (numeral(inputValue).value() !== '' && numeral(inputValue).value() !== null) {
									if (numeral(parseFloat(inputValue)).value() < 0) {
										message.error('El valor mínimo de la tasación debe ser 0');
									} else {
										saveData('mortgage', 'valueAppraisal', inputValue);
									}
								}
							}}
							gbType="currency"
						/>
					</div>
				</Col>
				<Col span={12}>
					{billingFormData && Object.keys(billingFormData).length > 0 && (
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>
								Nombre de facturación
							</Text>
							<Input
								defaultValue={billingFormData?.name || ''}
								onBlur={(e) => saveData('billing', 'name', e.target.value)}
								type="text"
								style={{ width: 286, marginTop: 8 }}
							/>
							<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858', marginTop: 16 }}>
								Dirección de facturación
							</Text>
							<Input
								defaultValue={billingFormData?.direction || ''}
								onBlur={(e) => saveData('billing', 'direction', e.target.value)}
								style={{ width: 286, marginTop: 8 }}
							/>
							<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858', marginTop: 16 }}>
								NIF/CIF de facturación
							</Text>
							<Input
								defaultValue={billingFormData?.nif || ''}
								onBlur={(e) => saveData('billing', 'nif', e.target.value)}
								style={{ width: 286, marginTop: 8 }}
							/>
						</div>
					)}
				</Col>
			</Row>

			{(mortgageData.bankAppraisalApproval === false ||
				mortgageData.bankAppraisalApproval === null ||
				mortgageData.bankAppraisalApproval === undefined) && (
				<Row>
					<Divider style={{ borderColor: '#F1F7F8' }} />
					<Row align={'middle'}>
						<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
							GESTIÓN DE LA TASACIÓN
						</Text>
						<Button
							type="primary"
							disabled={mortgageData.reportAppraisal === undefined ? true : false}
							onClick={() => showModalconfirm()}
							style={{ marginLeft: 30, marginTop: -5 }}>
							AVANZAR CON TASACIÓN
						</Button>
					</Row>
				</Row>
			)}

			{mortgageData.reportAppraisal && mortgageData.bankAppraisalApproval && (
				<Row style={{ paddingTop: 15 }}>
					<label>
						<strong>GESTIÓN DE LA TASACIÓN</strong>
					</label>

					<Button
						type="dashed"
						className="button-secundary-gibobs"
						onClick={() => setModalConfirmBack(true)}
						style={{ marginLeft: 30, marginTop: -5 }}>
						RETROCEDER CON TASACIÓN
					</Button>
				</Row>
			)}

			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: '49%', height: 40 }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: '49%', height: 40 }
				}}
				title={'Retroceder con tasación'}
				open={modalConfirmBack}
				width={480}
				onOk={() => {
					saveData('mortgage', 'bankAppraisalApproval', null);
					setModalConfirmBack(!modalConfirmBack);
				}}
				onCancel={() => {
					setModalConfirmBack(!modalConfirmBack);
				}}
				okText={'Confirmar'}
				cancelText={'Cancelar'}
				closable={true}>
				<div style={{ marginBottom: 32 }}>
					<Text style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}>
						¿Estás seguro de que quieres retroceder con la tasación?
					</Text>
				</div>
			</Modal>

			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: 86, height: 32 }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: 86, height: 32 }
				}}
				title={'¿Estás seguro de que deseas eliminarlo?'}
				open={modalConfirmDelete}
				width={400}
				onOk={handleOnDelete}
				onCancel={() => {
					setModalConfirmDelete(false);
				}}
				okText={'Eliminar'}
				cancelText={'Cancelar'}
				closable={true}>
				<div style={{ marginBottom: 32 }}>
					<Text style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}>
						Eliminaremos todos los datos que hayas indicado del rechazo de recomendador.
					</Text>
				</div>
			</Modal>

			<RegisterAssigment
				visible={visibleConfirmComponent}
				onCreate={handleSubmitForm}
				onCancel={() => setVisibleConfirmComponent(!visibleConfirmComponent)}
				width={800}
				bankAccountData={''}
			/>
		</Content>
	);
};
